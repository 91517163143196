/* eslint-disable no-undef */
var app = angular.module('PouchCue')
app.factory('Logger', function() {
  var service = {}
  var debug = false

  service.log = function(msg) {
    if (debug) {
      console.log(msg) //eslint-disable-line
    }
  }

  return service
})

app.factory('GlobalService', [
  '$http',
  '$rootScope',
  '$q',
  function($http, $rootScope, $q) {
    var service = {}
    var prefix = '/ajax'
    var debug = false //eslint-disable-line
    var user = {}

    service.urls = {
      ticketTypes: '/ticket/types'
    }

    service.post = function(url, data, callbackSuccess, callbackError) {
      service.http('POST', prefix + url, data, callbackSuccess, callbackError)
    }

    service.get = function(url, data, callbackSuccess, callbackError) {
      service.http('GET', prefix + url, data, callbackSuccess, callbackError)
    }

    service.delete = function(url, data, callbackSuccess, callbackError) {
      service.http('DELETE', prefix + url, data, callbackSuccess, callbackError)
    }

    service.http = function(method, url, data, callbackSuccess, callbackError) {
      $http({
        method: method,
        url: url,
        data: data,
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      }).then(
        function successCallback(data) {
          if (
            data &&
            data.data &&
            data.data.status &&
            data.data.status === 500
          ) {
            callbackError(data)
          } else {
            callbackSuccess(data)
          }
        },
        function errorCallback(data) {
          callbackError(data)
        }
      )
    }

    service.checkEmail = function(email, callbackSuccess, callbackError) {
      service.post(
        '/checkEmail',
        { email: email },
        function(data) {
          callbackSuccess(data)
        },
        function(data) {
          callbackError(data)
        }
      )
    }

    service.isStringNotBlank = function(str) {
      return str && !/^\s*$/.test(str)
    }

    service.random = function(min, max) {
      if (min === 0 && max === 1) {
        return Math.round(Math.random())
      } else {
        return Math.floor(Math.random() * max + min)
      }
    }

    service.checkIfRequiredFieldsFilled = function(fields) {
      for (var key in fields) {
        var value = fields[key]
        if (!value) {
          return false
        }
      }
      return true
    }

    service.serverError = function(allowMultiple) {
      service.hideGlobalLoader()
      $('#ServerError')
        .modal({
          duration: 200,
          allowMultiple: allowMultiple
        })
        .modal('show')
    }

    service.customError = function(title, message, closeCallback) {
      if (!isUnloading) {
        $('#CustomError .header .circle-header .title').html(title)
        $('#CustomError .description').html(message)

        if (typeof closeCallback === 'function') {
          $('#CustomError .close').click(function() {
            $('#CustomError')
              .modal({ duration: 200, closable: false })
              .modal('hide')
            closeCallback()
          })
          $('#CustomError .button.close').click(function() {
            $('#CustomError')
              .modal({ duration: 200, closable: false })
              .modal('hide')
            closeCallback()
          })
        }

        $('#CustomError')
          .modal({
            duration: 200
          })
          .modal('show')
      }
    }

    service.customModal = function(modal) {
      $('#' + modal)
        .modal({ duration: 200, closable: false })
        .modal('show')
    }

    service.closeCustomModal = function(modal) {
      $('#' + modal)
        .modal({ duration: 200, closable: false })
        .modal('hide')
    }

    service.scrollTo = function(parent, elem, offset) {
      $(parent).animate(
        {
          scrollTop: $(elem).offset().top - offset
        },
        500
      )
    }

    service.scrollToError = function() {
      if ($('.error').length) {
        $('html, body').animate(
          {
            scrollTop: $('.error').offset().top - 150 + 'px'
          },
          'normal'
        )
        $('.error').focus()
        $('.error')
          .children(':first')
          .focus()
      }
    }

    service.hideCustomError = function() {
      $('#CustomError').modal('hide')
    }

    service.showGlobalLoader = function(status) {
      $('#GlobalLoaderContent').html(status)
      $('#GlobalLoader').fadeIn()
    }

    service.hideGlobalLoader = function() {
      $('#GlobalLoader').fadeOut()
    }

    service.submitSuccess = function(title, message) {
      $('#SubmitSuccess .header .circle-header .title').html(title)
      $('#SubmitSuccess .description').html(message)
      $('#SubmitSuccess')
        .modal({ blurring: true, duration: 200 })
        .modal('show')
    }

    service.submitSuccessReset = function(title, message) {
      $('#SubmitSuccessReset .header .circle-header .title').html(title)
      $('#SubmitSuccessReset .description').html(message)
      $('#SubmitSuccessReset')
        .modal({ blurring: true, duration: 200 })
        .modal('show')
    }

    service.submitSuccessReload = function(title, message) {
      $('#SubmitSuccessReload .header .circle-header .title').html(title)
      $('#SubmitSuccessReload .description').html(message)
      $('#SubmitSuccessReload')
        .modal({
          blurring: true,
          duration: 200,
          onApprove: function() {
            return false
          }
        })
        .modal('show')
    }

    service.customRedirectError = function(title, message, route) {
      $('#CustomError300 .header .circle-header .title').html(title)
      $('#CustomError300 .description').html(message)
      $('#CustomError300')
        .modal({ duration: 200 })
        .modal('show')
      $('#CustomErrOk300').click(function() {
        if (!route) {
          return window.location.reload(true)
        }
        window.location.href = route
      })
    }

    service.goBackError = function(title, message, route) {
      $('#GoBackError .header .circle-header .title').html(title)
      $('#GoBackError .description').html(message)
      $('#GoBackError')
        .modal({
          blurring: true,
          duration: 200,
          closable: false,
          onApprove: function() {
            return false
          }
        })
        .modal('show')
      $('#GoBackErrorOk').click(function() {
        window.location.href = route
      })
    }

    service.goLogin = function(title, message) {
      $('#GoBackError .header .circle-header .title').html(title)
      $('#GoBackError .description').html(message)
      $('#GoBackErrorOk').html('Login')
      $('#GoBackError')
        .modal({
          blurring: true,
          duration: 200,
          closable: false,
          onApprove: function() {
            return false
          }
        })
        .modal('show')
      $('#GoBackErrorOk').click(function() {
        window.location.href = window.location.origin + '/auth/login'
      })
    }

    service.subscribeLoginClicked = function(scope, callback) {
      var handler = $rootScope.$on('notifying-login-click-event', callback)
      scope.$on('$destroy', handler)
    }

    service.notifyLoginClicked = function() {
      $rootScope.$emit('notifying-login-click-event')
    }

    service.getPromise = function(url, data) {
      return $q(function(resolve, reject) {
        service.get(
          url,
          data,
          function success(response) {
            resolve(response.data)
          },
          function error() {
            reject('GET Request Failed: ' + url)
          }
        )
      })
    }

    service.postPromise = function(url, data) {
      return $q(function(resolve, reject) {
        service.post(
          url,
          data,
          function success(response) {
            resolve(response.data)
          },
          function error() {
            reject('POST Request Failed: ' + url)
          }
        )
      })
    }

    service.getCountries = function(callback, callbackError) {
      service.get('/countries', {}, callback, callbackError)
    }

    service.scrollTo = function(id) {
      $('html, body').animate(
        {
          scrollTop: $('#' + id).offset().top - 150 + 'px'
        },
        'normal'
      )
      $('#' + id).focus()
      $('#' + id)
        .children(':first')
        .focus()
    }

    service.setUser = function(data) {
      user = data
    }

    service.getUser = function() {
      return user
    }

    service.isFeatureEnabled = function(feat, callback) {
      if (typeof callback === 'function') {
        service.post(
          '/feature-flags',
          { flags: [feat] },
          function(response) {
            callback(response.data[feat])
          },
          function() {
            callback(false)
          }
        )
      } else {
        if (typeof flags !== 'undefined' && typeof flags === 'object') { //eslint-disable-line
          return flags[feat] ? flags[feat] : false //eslint-disable-line
        }
        return false
      }
    }

    service.hasTimeSchedule = function(eventId) {
      if (
        !service.isFeatureEnabled('PN_4632_ASYLUM_EVENT') ||
        (service.isFeatureEnabled('PN_4632_ASYLUM_EVENT') && eventId !== 3969)
      ) {
        return true
      }
      return false
    }

    service.isWholeNumber = function(n) {
      // number is positive integer, and should not have decimals, accept 0 as value
      var ret = n % 1 === 0 && n >= 0 && n.toString().indexOf('.') === -1
      return ret
    }

    service.isValidMobileNumber = function(number) {
      if (number) {
        var num = number.toString()
        if (num.charAt(0) === '+') {
          num = num.replace('+', '')
        }
        var mobile = num.trim()
        var regex = /[ !@#$%^&*()_\-=[\]{};':"\\|,.<>/?]/g
        return service.isWholeNumber(mobile) ? !regex.test(mobile) : false
      }
      return false
    }

    service.isEmailValid = function(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
      return re.test(email)
    }

    service.validateValue = function(validation, value, required) {
      if (required && !value) {
        return false
      }
      switch (validation) {
        case 'mobileNumber':
          return !service.isValidMobileNumber(value)
        case 'email':
          return !service.isEmailValid(value)
        case 'number':
          return !service.isWholeNumber(value)
        default:
          return false
      }
    }

    return service
  }
])
